<template>
	<section class="absolute top-0 left-0 h-screen w-screen">
		<div class="fixed top-0 left-0 w-screen h-screen bg-black/75 z-[200]" @click="$emit('close')"></div>
		<div class="absolute bottom-12 md:top-1/2 left-1/2 -translate-x-1/2 md:-translate-y-1/2 z-[201]">
			<div class="relative h-fit w-fit">
				<div class="bg-admin-gray-500 h-fit w-[90vw] md:w-[504px] overflow-auto rounded-3xl p-8">
					<slot></slot>
				</div>
				<button
					@click="$emit('close')"
					class="absolute top-0 right-0 md:-right-24 h-[60px] w-[60px] bg-admin-gray-500 flex items-center justify-center rounded-full"
				>
					<i class="fi fi-br-cross-small"></i>
				</button>
			</div>
		</div>
	</section>
</template>
<script setup lang="ts">
onMounted(() => {
	document.body.style.overflow = 'hidden'
})

onUnmounted(() => {
	document.body.style.overflow = ''
})
</script>
